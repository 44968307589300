import { forwardRef, useEffect, useState } from 'react'
import { useFormik } from 'formik'
import DatePicker from 'react-datepicker'
import Select from 'react-select'
import CurrencyInput from 'react-currency-input-field'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlusCircle, faTimesCircle } from '@fortawesome/free-solid-svg-icons'
import { FormattedMessage } from 'react-intl'
import ElementList from './ElementList'
import LoadingSpinner from 'components/common/LoadingSpinner'
import ERROR_MESSAGES from 'utils/errorMessages'

import 'react-datepicker/dist/react-datepicker.css'

const validate = (values) => {
  const errors = {}

  if (!values.promotionCode) {
    errors.promotionCode = ERROR_MESSAGES.REQUIRED
  }

  if (!values.amount) {
    errors.amount = ERROR_MESSAGES.REQUIRED
  } else if (values.amount <= 0) {
    errors.amount = 'Credit amount must be greater than 0'
  } else if (values.amount > 400000) {
    errors.amount = 'Credit amount must be less than $400,000'
  }

  if (!values.accountId) {
    errors.accountId = ERROR_MESSAGES.REQUIRED
  }

  if (!values.startDate) {
    errors.startDate = ERROR_MESSAGES.REQUIRED
  }

  if (!values.endDate) {
    errors.endDate = ERROR_MESSAGES.REQUIRED
  }

  if (values.allocations) {
    if (!values.allocations.startDate) {
      errors.allocationsStartDate = ERROR_MESSAGES.REQUIRED
    }

    if (values.allocations?.breakdown?.length) {
      errors.breakdown = []
      const totalAllocation = values.allocations.breakdown.reduce((acc, item) => acc + +item.amount, 0)
      const hasAllocationError = totalAllocation !== +values.amount
      values.allocations.breakdown.forEach((breakdown, index) => {
        errors.breakdown[index] = {}
        if (!breakdown.element1Id) {
          errors.breakdown[index].element1Id = ERROR_MESSAGES.REQUIRED
        }

        if (!breakdown.element2Id) {
          errors.breakdown[index].element2Id = ERROR_MESSAGES.REQUIRED
        }

        if (!breakdown.element3Id) {
          errors.breakdown[index].element3Id = ERROR_MESSAGES.REQUIRED
        }

        if (!breakdown.element4Id) {
          errors.breakdown[index].element4Id = ERROR_MESSAGES.REQUIRED
        }

        if (!breakdown.amount) {
          errors.breakdown[index].amount = ERROR_MESSAGES.REQUIRED
        } else if (hasAllocationError) {
          errors.breakdown[index].amount = 'Unallocated amount'
        }
      })

      const breakdowns = errors.breakdown.filter((item) => Object.keys(item).length !== 0)

      if (breakdowns.length === 0) {
        delete errors.breakdown
      }
    }
  }

  return errors
}

const getAccountsOptions = (accounts) => {
  return accounts.map((account) => ({
    value: account.accountId,
    label: account.accountId
  }))
}

const CreditForm = ({ formEl, data = null, accounts, sorElements, save, isReapply = false }) => {
  const [isLoading, setIsLoading] = useState(false)
  const [areSorElementsLoaded, setAreSorElementsLoaded] = useState(false)
  const accountsOptions = getAccountsOptions(accounts)

  const DateButton = forwardRef(({ value, onClick }, ref) => (
    <button type="button" className="btn btn-primary btn-sm" onClick={onClick} ref={ref}>
      {value || 'Select Date'}
    </button>
  ))

  useEffect(() => {
    setIsLoading(true)
    const { elementList1 } = sorElements

    if (elementList1) {
      setIsLoading(false)
      setAreSorElementsLoaded(true)
    }
  }, [sorElements])

  useEffect(() => {
    if (data) {
      const { year, promotionCode, reference, amount, startDate, endDate, elementMap } = data

      formik.setValues({
        ...formik.values,
        reference,
        promotionCode,
        amount,
        startDate,
        endDate,
        element1Id: elementMap.element1Id,
        element2Id: elementMap.element2Id,
        element3Id: elementMap.element3Id,
        element4Id: elementMap.element4Id
      })
    }
  }, [data])

  const formik = useFormik({
    initialValues: {
      promotionCode: '',
      amount: '',
      accountId: '',
      reference: '',
      element1Id: '',
      element2Id: '',
      element3Id: '',
      element4Id: '',
      startDate: '',
      endDate: ''
    },
    validate,
    validateOnMount: true,
    onSubmit: () => {
      const creditMapping = {
        ...formik.values
      }
      save(creditMapping)
    }
  })

  useEffect(() => {
    formEl.current = formik
  }, [formEl, formik])

  const { elementList1, elementList2, elementList3, elementList4 } = sorElements

  return (
    <>
      {isLoading && <LoadingSpinner />}
      <div className="card mb-3">
        <div className="card-body">
          <form onSubmit={formik.handleSubmit} autoComplete="off" className="mb-4">
            <div className="credit-form mb-3">
              <div className="field">
                <label htmlFor="promotionCode">Promotion Code</label>
                <input
                  id="promotionCode"
                  type="text"
                  className="form-control"
                  value={formik.values.promotionCode}
                  onChange={formik.handleChange}
                  disabled={isReapply}
                />
                {formik.errors.promotionCode && formik.touched.promotionCode && (
                  <div className="text-error">{formik.errors.promotionCode}</div>
                )}
              </div>
              <div className="field">
                <label htmlFor="creditAmount">Credit Amount</label>
                <CurrencyInput
                  prefix="$"
                  id="creditAmount"
                  disabled={isReapply}
                  value={formik.values.amount}
                  decimalsLimit={2}
                  onValueChange={(value) => {
                    formik.setFieldValue('amount', value)
                  }}
                  className="form-control"
                  placeholder="$0.00"
                />
                {formik.errors.amount && formik.touched.amount && (
                  <div className="text-error">{formik.errors.amount}</div>
                )}
              </div>
              <div className="field">
                <label htmlFor="accountId">Applied to AWS Account</label>
                <Select
                  id="accountId"
                  className="react-select"
                  classNamePrefix="select"
                  options={accountsOptions}
                  value={accountsOptions.find((option) => option.value === formik.values.accountId) || null}
                  onChange={(data) => formik.setFieldValue('accountId', data?.value)}
                  isClearable
                />
                {formik.errors.accountId && formik.touched.accountId && (
                  <div className="text-error">{formik.errors.accountId}</div>
                )}
              </div>
            </div>
            <div className="credit-form mb-3">
              <div className="field">
                <label htmlFor="reference">Reference</label>
                <input
                  id="reference"
                  type="text"
                  className="form-control"
                  disabled={isReapply}
                  value={formik.values.reference}
                  onChange={formik.handleChange}
                />
                {formik.errors.reference && formik.touched.reference && (
                  <div className="text-error">{formik.errors.reference}</div>
                )}
              </div>
              <div className="field">
                <label htmlFor="reference">Date Applied to AWS</label>
                <DatePicker
                  showIcon
                  autoComplete="off"
                  id="dateAppliedToAWS"
                  disabled={isReapply}
                  selected={formik.values.startDate}
                  minDate={new Date()}
                  onChange={(date) => {
                    formik.setFieldValue('startDate', date)
                  }}
                  className="form-control"
                />
                {formik.errors.startDate && formik.touched.startDate && (
                  <div className="text-error">{formik.errors.startDate}</div>
                )}
              </div>
              <div className="field">
                <label htmlFor="expirationDate">Expiration</label>
                <DatePicker
                  showIcon
                  autoComplete="off"
                  id="expirationDate"
                  minDate={formik.values.startDate}
                  selected={formik.values.endDate}
                  onChange={(date) => {
                    formik.setFieldValue('endDate', date)
                  }}
                  disabled={isReapply || formik.values.startDate === ''}
                  className="form-control"
                />
                {formik.errors.endDate && formik.touched.endDate && (
                  <div className="text-error">{formik.errors.endDate}</div>
                )}
              </div>
            </div>
            <div>
              <table className="table table-bordered">
                <thead>
                  <tr>
                    <th>
                      <FormattedMessage id="sor.element1Name" defaultMessage="Business Unit" />
                    </th>
                    <th>
                      <FormattedMessage id="sor.element2Name" defaultMessage="Department" />
                    </th>
                    <th>
                      <FormattedMessage id="sor.element3Name" defaultMessage="Portfolio" />
                    </th>
                    <th>
                      <FormattedMessage id="sor.element4Name" defaultMessage="Product" />
                    </th>
                  </tr>
                </thead>
                {areSorElementsLoaded && (
                  <tbody>
                    <tr>
                      <td>
                        <ElementList
                          id={formik.values.element1Id}
                          elementType="element1"
                          elements={elementList1}
                          onChange={(data) => formik.setFieldValue('element1Id', data.id)}
                          dropDownLabel={formik.values.element1Id || 'please select'}
                        />

                        {formik.errors?.element1Id && formik.touched.element1Id && (
                          <div className="text-error">{formik.errors.element1Id}</div>
                        )}
                      </td>
                      <td>
                        <ElementList
                          id={formik.values.element2Id}
                          elementType="element2"
                          elements={elementList2.filter((el) => el.element1Id === formik.values.element1Id)}
                          onChange={(data) => formik.setFieldValue('element2Id', data.id)}
                          dropDownLabel={formik.values.element2Id || 'please select'}
                        />

                        {formik.errors?.element2Id && formik.touched.element2Id && (
                          <div className="text-error">{formik.errors.element2Id}</div>
                        )}
                      </td>
                      <td>
                        <ElementList
                          id={formik.values.element3Id}
                          elementType="element3"
                          elements={elementList3.filter((el) => el.element2Id === formik.values.element2Id)}
                          onChange={(data) => formik.setFieldValue('element3Id', data.id)}
                          dropDownLabel={formik.values.element3Id || 'please select'}
                        />
                        {formik.errors?.element3Id && formik.touched.element3Id && (
                          <div className="text-error">{formik.errors.element3Id}</div>
                        )}
                      </td>
                      <td>
                        <ElementList
                          id={formik.values.element4Id}
                          elementType="element4"
                          elements={elementList4.filter((el) => el.element3Id === formik.values.element3Id)}
                          onChange={(data) => formik.setFieldValue('element4Id', data.id)}
                          dropDownLabel={formik.values.element4Id || 'please select'}
                        />
                        {formik.errors?.element4Id && formik.touched.element4Id && (
                          <div className="text-error">{formik.errors.element4Id}</div>
                        )}
                      </td>
                    </tr>
                  </tbody>
                )}
              </table>
            </div>
            <div className="text-center">
              <button className="btn btn-primary btn-sm">Apply</button>
            </div>
          </form>
        </div>
      </div>
    </>
  )
}
export default CreditForm
