import { Card, Col, Row } from 'react-bootstrap'
import { Status, TimeCardStatus } from '../../constants/TimeCardStatus'
import CardDetails from './CardDetails'
import React, { useEffect, useState } from 'react'
import { getNumberOfDays } from 'Summary/helpers/getDateRange'

const TimeCards = ({ timeCards, inputMonth, budgetsData }) => {
  const [mappedData, setMappedData] = useState([])

  useEffect(() => {
    const mapped = {}
    const budgets = budgetsData?.data || []
    let products = []

    budgets.forEach((budget) => {
      const departments = budget?.departments || []
      departments.forEach((department) => {
        const portfolios = department.portfolios || []
        portfolios.forEach((portfolio) => {
          products = products.concat(portfolio.products || [])
        })
      })
    })

    timeCards.forEach((item) => {
      let key = item.status
      if (item.status === TimeCardStatus['RESOLVE']) {
        key = Status['Open']
      }

      const data = {
        ...item,
        budgets: products.find((product) => product.element4 === item.elementMap.element4Id)?.budgets,
        numOfDays: getNumberOfDays(item.week, inputMonth, item.year)
      }

      if (mapped[key]) {
        mapped[key].push(data)
      } else {
        mapped[key] = [data]
      }
    })

    setMappedData(mapped)
  }, [timeCards, budgetsData])

  return (
    <div className="time-cards pb-5">
      <Row xs={1} md={2} className="g-4">
        <Col>
          <Card>
            <Card.Body>
              {mappedData['Open'] ? (
                <CardDetails inputMonth={inputMonth} items={mappedData['Open']} type="Open" />
              ) : (
                <Card.Title>No Open Cards</Card.Title>
              )}
            </Card.Body>
          </Card>
        </Col>

        <Col>
          <Card>
            <Card.Body>
              {mappedData['Accept'] ? (
                <CardDetails inputMonth={inputMonth} items={mappedData['Accept']} type="Accept" />
              ) : (
                <Card.Title>No Accepted Cards</Card.Title>
              )}
            </Card.Body>
          </Card>
        </Col>

        <Col>
          <Card>
            <Card.Body>
              {mappedData['Dispute'] ? (
                <CardDetails inputMonth={inputMonth} items={mappedData['Dispute']} type="Dispute" />
              ) : (
                <Card.Title>No Disputed Cards</Card.Title>
              )}
            </Card.Body>
          </Card>
        </Col>

        <Col>
          <Card>
            <Card.Body>
              {mappedData['Approve'] ? (
                <CardDetails inputMonth={inputMonth} items={mappedData['Approve']} type="Approve" />
              ) : (
                <Card.Title>No Approved Cards</Card.Title>
              )}
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </div>
  )
}

export default TimeCards
