const getCreditsUseCase = ({ month, year, token }, { observer, actualsRepo }) => {
  actualsRepo.getCredits(
    { month, year, token },
    {
      success: (response) => {
        if (response.error) {
          observer.errorGettingCredits()
          return
        }

        observer.receiveCredits({
          creditsUsed: response.data
        })
      },
      failure: (e) => {
        observer.errorGettingCredits()
      }
    }
  )
}

export default getCreditsUseCase
