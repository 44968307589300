import {
  getColorCharts,
  getEOYPlannedBudget,
  getSpendQuarter,
  getTotalBudgetAndSpend,
  getUnallocatedBudget
} from 'Budgets/helpers/functions'
import SemiCircularProgressbar from 'components/common/SemiCircularProgressbar/SemiCircularProgressbar'
import { Card, Col, Row } from 'react-bootstrap'
import dollarPriceFormatter from 'formatters/dollar_price_formatter'

import './Totals.scss'
import TreeMapBudgets from '../TreeMapBudgets/TreeMapBudgets'

const currentMonth = new Date().getMonth()

function Totals({ budgets = [], isMonthly }) {
  const { totalBudget, totalSpend, percentage } = getTotalBudgetAndSpend(budgets, isMonthly)
  const lastMonthData = getTotalBudgetAndSpend(budgets, true, currentMonth - 1)
  const totalSpendQuarter = getSpendQuarter(budgets)
  const percentageColor = getColorCharts(percentage)

  const variance = totalBudget - totalSpend

  const lastMonthVariance = lastMonthData.totalSpend - lastMonthData.totalBudget
  const unallocatedBudget = getUnallocatedBudget(budgets)

  return (
    <div className="totals-section">
      <div className="d-flex justify-content-center gap-5">
        <div className="mini-card">
          <h4>Spend</h4>
          <div className="mini-card--body fw-light">{dollarPriceFormatter(totalSpend)}</div>
        </div>
        <div className="mini-card">
          <h4>Planned</h4>
          <div className="mini-card--body fw-light">{dollarPriceFormatter(totalBudget)}</div>
        </div>
        <div className="mini-card">
          <h4>This Quarter</h4>
          <div className="mini-card--body fw-light">{dollarPriceFormatter(totalSpendQuarter)}</div>
        </div>
      </div>

      <Row>
        <Col>
          <TreeMapBudgets budgets={budgets} isMonthly={isMonthly} />
        </Col>
        <Col>
          <Card className="card-budget total-card card-shadow mb-3">
            <Card.Body className="d-flex">
              <div className="card-progressbar d-flex align-items-center ">
                <SemiCircularProgressbar percent={percentage} radio="120" />
              </div>
              <Card className={`card-color card-${percentageColor}`}>
                <Card.Body>
                  <Card.Subtitle className="text-uppercase">Variance In Spend</Card.Subtitle>
                  <h2 className="mb-3">{dollarPriceFormatter(variance)}</h2>
                  {isMonthly ? (
                    <>
                      <Card.Subtitle className="text-uppercase">Last Month</Card.Subtitle>
                      <h3 className="mb-3">{dollarPriceFormatter(lastMonthVariance)}</h3>
                    </>
                  ) : (
                    <>
                      <Card.Subtitle className="text-uppercase">Unallocated Budget</Card.Subtitle>
                      <h3 className="mb-3">{dollarPriceFormatter(unallocatedBudget)}</h3>
                      <Card.Subtitle className="text-uppercase">EOY Planned</Card.Subtitle>
                      <h3 className="mb-3">{dollarPriceFormatter(getEOYPlannedBudget(budgets))}</h3>
                    </>
                  )}
                </Card.Body>
              </Card>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </div>
  )
}
export default Totals
