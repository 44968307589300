const getCreditMapping = (
  { token, year, element1Id, element2Id, element3Id, element4Id },
  { creditRepo, observer }
) => {
  creditRepo.getCreditMappingByElement(
    { year, token, element1Id, element2Id, element3Id, element4Id },
    {
      success: (response) => {
        const { getCreditMappingByElement } = response.data.data
        if (getCreditMappingByElement.error) {
          observer.failure(getCreditMappingByElement.error)
          return
        }

        observer.success(getCreditMappingByElement.data)
      },
      failure: (error) => {
        observer.failure(error)
      }
    }
  )
}

export default getCreditMapping
