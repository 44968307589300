import React, { useContext, useEffect, useRef, useState } from 'react'
import { Card, Button } from 'react-bootstrap'
import Modal from 'react-bootstrap/Modal'

import { toast } from 'react-toastify'

import createVendorDetails from '../use-cases/createVendorDetails'
import AppContext from 'context/AppContext'
import getVendorDetails from '../use-cases/getVendorDetails'
import LoadingSpinner from 'components/common/LoadingSpinner'
import PricingTable from './PricingTable'
import PricingForm from './PricingForm'

const Pricing = () => {
  const { repoFactory, selectedYear } = useContext(AppContext)
  const formRef = useRef()
  const [isLoading, setIsLoading] = useState(false)
  const [vendorDetails, setVendorDetails] = useState(null)
  const [show, setShow] = useState(false)

  const handleClose = () => setShow(false)
  const handleShow = () => setShow(true)

  useEffect(() => {
    getVendor()
  }, [selectedYear, repoFactory])

  const getVendor = async () => {
    setIsLoading(true)
    getVendorDetails(
      {
        year: selectedYear,
        token: localStorage.getItem('authToken')
      },
      {
        vendorRepo: repoFactory.vendorRepo(),
        observer: {
          failure: (error) => {
            setIsLoading(false)
            toast.error('There was an error, please try again.')
          },
          success: (data) => {
            setIsLoading(false)
            setVendorDetails(data)
          }
        }
      }
    )
  }

  const save = async () => {
    const form = formRef.current
    await form?.submitForm()
    if (form?.isValid) {
      setIsLoading(true)
      createVendorDetails(
        {
          token: localStorage.getItem('authToken'),
          vendor: {
            ...form.values
          },
          year: selectedYear
        },
        {
          vendorRepo: repoFactory.vendorRepo(),
          observer: {
            failure: (error) => {
              toast.error('There was an error, please try again.')
              setIsLoading(false)
            },
            success: (data) => {
              handleClose()
              toast.success('Vendor details saved successfully.')
              getVendor()
            }
          }
        }
      )
    }
  }

  return (
    <>
      {isLoading && <LoadingSpinner />}
      <div className="mb-3">
        <button type="button" className="btn btn-primary btn-sm" onClick={handleShow}>
          New
        </button>
      </div>
      <PricingTable vendorDetails={vendorDetails} />

      <Modal show={show} onHide={handleClose} size="lg">
        <Modal.Body>
          <h2>New Pricebook</h2>
          <Card className="section-gray mb-3">
            <Card.Body>
              <PricingForm formEl={formRef} />
            </Card.Body>
          </Card>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary" onClick={save}>
            Save
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  )
}

export default Pricing
