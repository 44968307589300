const releaseResource = ({ token, accountId, resources }, { observer, spacesRepo }) => {
  spacesRepo.releaseResource(
    { token, accountId, resources },
    {
      success: (response) => {
        if (response.error) {
          observer.failure(response.error)
          return
        }

        observer.success(response.data)
      },
      failure: (error) => {
        observer.failure(error)
      }
    }
  )
}

export default releaseResource
