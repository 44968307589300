function BudgetsRepo({ GRAPHQL_URL, GRAPHQL_API_KEY, httpClient }) {
  this.getAllBudgetsNew = ({ year, token }, { success, failure }) => {
    const query = `query ($token: String!, $year: Int!) {
                          getAllBudgetsNew(budgetParams: {token: $token, year: $year}) {
                            data {
                              usedYTD
                              name
                              id
                              amount
                              departments {
                                ...DPDataFragment
                              }
                            }
                            error {
                              code
                              message
                              statusCode
                            }
                          }
                        }
                        
                        fragment usageMapItemFragment on usageMapItem {
                          cost
                          credit
                          discountedUsage
                          fee
                          tax
                          savingsPlanNegation
                          savingsPlanCoverage
                          savingPlanUpfrontFee
                          savingPlanRecurringFee
                          riFee
                          refund
                        }
                        
                        fragment BudgetUsageMapFragment on BudgetUsageMap {
                          _0
                          _1
                          _10
                          _11
                          _2
                          _3
                          _4
                          _5
                          _6
                          _7
                          _8
                          _9
                        }
                        
                        fragment PRODDataFragment on PRODData {
                          amount
                          element1
                          element2
                          element3
                          element4
                          id
                          status
                          usedYTD
                          executionId
                          requestedBy
                          reviewedBy
                          estimatedBudgets {
                              _0
                              _1
                              _2
                              _3
                              _4
                              _5
                              _6
                              _7
                              _8
                              _9
                              _10
                              _11
                            }
                          budgets {
                            ...BudgetUsageMapFragment
                          }
                          actuals {
                            _0 {
                              ...usageMapItemFragment
                            }
                            _1 {
                              ...usageMapItemFragment
                            }
                            _2 {
                              ...usageMapItemFragment
                            }
                            _3 {
                              ...usageMapItemFragment
                            }
                            _4 {
                              ...usageMapItemFragment
                            }
                            _5 {
                              ...usageMapItemFragment
                            }
                            _6 {
                              ...usageMapItemFragment
                            }
                            _7 {
                              ...usageMapItemFragment
                            }
                            _8 {
                              ...usageMapItemFragment
                            }
                            _9 {
                              ...usageMapItemFragment
                            }
                            _10 {
                              ...usageMapItemFragment
                            }
                            _11 {
                              ...usageMapItemFragment
                            }
                          }
                        }
                        
                        fragment PORTDataFragment on PORTData {
                          amount
                          id
                          name
                          usedYTD
                          products {
                            ...PRODDataFragment
                          }
                        }
                        
                        fragment DPDataFragment on DPData {
                          amount
                          id
                          name
                          portfolios {
                            ...PORTDataFragment
                          }
                          usedYTD
                        }
`

    httpClient.request(
      {
        method: 'POST',
        url: GRAPHQL_URL,
        headers: {
          'x-api-key': GRAPHQL_API_KEY
        },
        data: {
          query,
          variables: {
            token,
            year
          }
        }
      },
      {
        success: (response) => {
          const { getAllBudgetsNew } = response.data.data
          success(getAllBudgetsNew)
        },
        failure: (error) => failure(error)
      }
    )
  }

  this.createBudget = (
    { year, token, element1, element2, element3, element4, budgets, isReschedule },
    { success, failure }
  ) => {
    const query = `mutation ($token: String!, $year: Int!, $budgets: Budgets!, $element1: String!, $element2: String!, $element3: String!, $element4: String!, $isReschedule: Boolean!) {
              createBudget(createParams: {budgets: $budgets, element1: $element1, element2: $element2, element3: $element3, element4: $element4, token: $token, year: $year, isReschedule: $isReschedule}) {
                element1
                element2
                element3
                element4
                executionId
                requestedBy
                reviewedBy
                status
                year
                budgets {
                  _0
                  _1
                  _2
                  _3
                  _4
                  _5
                  _6
                  _7
                  _8
                  _9
                  _10
                  _11
                }
              }
          }`

    httpClient.request(
      {
        method: 'POST',
        url: GRAPHQL_URL,
        headers: {
          'x-api-key': GRAPHQL_API_KEY
        },
        data: {
          query,
          variables: {
            token,
            year,
            element1,
            element2,
            element3,
            element4,
            budgets,
            isReschedule
          }
        }
      },
      {
        success: (response) => {
          success(response.data.data)
        },
        failure: (error) => failure(error)
      }
    )
  }

  this.reSubmitBudget = ({ token, budgets, executionId }, { success, failure }) => {
    const query = `mutation ($token: String!, $budgets: Budgets!, $executionId: String!) {
              updateBudget(updateParams: {budgets: $budgets, executionId: $executionId, token: $token}) {
                element1
                element2
                element3
                element4
                executionId
                requestedBy
                reviewedBy
                status
                year
                budgets {
                  _0
                  _1
                  _2
                  _3
                  _4
                  _5
                  _6
                  _7
                  _8
                  _9
                  _10
                  _11
                }
              }
          }`

    httpClient.request(
      {
        method: 'POST',
        url: GRAPHQL_URL,
        headers: {
          'x-api-key': GRAPHQL_API_KEY
        },
        data: {
          query,
          variables: {
            token,
            executionId,
            budgets
          }
        }
      },
      {
        success: (response) => {
          success(response.data.data)
        },
        failure: (error) => failure(error)
      }
    )
  }

  this.submitReview = ({ token, executionId, status }, { success, failure }) => {
    const query = `mutation ($token: String!, $status: String!, $executionId: String!) {
              reviewBudget(reviewParams: {executionId: $executionId, token: $token, status: $status}) {
                element1
                element2
                element3
                element4
                executionId
                requestedBy
                reviewedBy
                status
                year
                budgets {
                  _0
                  _1
                  _2
                  _3
                  _4
                  _5
                  _6
                  _7
                  _8
                  _9
                  _10
                  _11
                }
              }
          }`

    httpClient.request(
      {
        method: 'POST',
        url: GRAPHQL_URL,
        headers: {
          'x-api-key': GRAPHQL_API_KEY
        },
        data: {
          query,
          variables: {
            token,
            executionId,
            status
          }
        }
      },
      {
        success: (response) => {
          success(response.data.data)
        },
        failure: (error) => failure(error)
      }
    )
  }
}

export default BudgetsRepo
