import Roles from './config/Roles'
const userRole = JSON.parse(localStorage.getItem('roles'))

const Routes = {
  WELCOME_PAGE: {
    PATH: '/welcome',
    TITLE: 'Welcome',
    PERMISSIONS: [Roles.ADMINS]
  },
  ACCOUNT_MAPPER: {
    PATH: '/admin/account-mapper',
    TITLE: 'submenu.chartOfAccount',
    PERMISSIONS: [Roles.FINANCIAL_ADMINS]
  },
  USER_MAPPER: {
    PATH: '/admin/user-mapper',
    TITLE: 'submenu.userEnrollment',
    PERMISSIONS: [Roles.FINANCIAL_ADMINS]
  },
  MONTH_CLOSE: {
    PATH: '/admin/month-close',
    TITLE: 'Month Close',
    PERMISSIONS: [Roles.FINANCIAL_ADMINS, Roles.BU_MANAGERS]
  },
  CREDIT_MANAGEMENT: {
    PATH: '/admin/credit-management',
    TITLE: 'Credit Management',
    PERMISSIONS: [Roles.FINANCIAL_ADMINS]
  },
  CONFIGURATION: {
    PATH: '/admin/configuration',
    TITLE: 'Configuration',
    PERMISSIONS: [Roles.ADMINS]
  },
  REPORTING: {
    PATH: '/reporting',
    TITLE: 'Reporting',
    PERMISSIONS: [
      Roles.FINANCIAL_ADMINS,
      Roles.BU_MANAGERS,
      Roles.DEPARTMENT_MANAGERS,
      Roles.PORTFOLIO_MANAGERS,
      Roles.PRODUCT_MANAGERS
    ]
  },
  PERIOD_CARDS: {
    PATH: '/reporting/period-cards',
    TITLE: 'submenu.periodCards',
    PERMISSIONS: [
      Roles.FINANCIAL_ADMINS,
      Roles.BU_MANAGERS,
      Roles.DEPARTMENT_MANAGERS,
      Roles.PORTFOLIO_MANAGERS,
      Roles.PRODUCT_MANAGERS
    ]
  },
  MONTHLY_ALLOCATION: {
    PATH: '/reporting/monthly-allocation',
    TITLE: 'submenu.monthlyAllocation',
    PERMISSIONS: [
      Roles.FINANCIAL_ADMINS,
      Roles.BU_MANAGERS,
      Roles.DEPARTMENT_MANAGERS,
      Roles.PORTFOLIO_MANAGERS,
      Roles.PRODUCT_MANAGERS
    ]
  },
  VARIANCE: {
    PATH: '/reporting/variance',
    TITLE: 'submenu.variance',
    PERMISSIONS: [
      Roles.FINANCIAL_ADMINS,
      Roles.BU_MANAGERS,
      Roles.DEPARTMENT_MANAGERS,
      Roles.PORTFOLIO_MANAGERS,
      Roles.PRODUCT_MANAGERS
    ]
  },
  BUSINESS_INTELLIGENCE: {
    PATH: '/reporting/business-intelligence',
    TITLE: 'submenu.businessIntelligence',
    PERMISSIONS: [
      Roles.FINANCIAL_ADMINS,
      Roles.BU_MANAGERS,
      Roles.DEPARTMENT_MANAGERS,
      Roles.PORTFOLIO_MANAGERS,
      Roles.PRODUCT_MANAGERS
    ]
  },
  ADMIN: {
    PATH: '/admin',
    TITLE: userRole && userRole.includes(Roles.FINANCIAL_ADMINS) ? 'Admin' : 'Onboarding',
    PERMISSIONS: [Roles.ADMINS, Roles.FINANCIAL_ADMINS, Roles.BU_MANAGERS]
  },
  ACCOUNT: {
    PATH: '/admin/account',
    TITLE: 'Account',
    PERMISSIONS: [Roles.ADMINS]
  },
  USER: {
    PATH: '/admin/user',
    TITLE: 'User',
    PERMISSIONS: [Roles.ADMINS]
  },
  DASHBOARD: {
    PATH: '/',
    TITLE: 'Summary',
    PERMISSIONS: [
      Roles.FINANCIAL_ADMINS,
      Roles.BU_MANAGERS,
      Roles.DEPARTMENT_MANAGERS,
      Roles.PORTFOLIO_MANAGERS,
      Roles.PRODUCT_MANAGERS,
      Roles.VENDOR_MANAGERS
    ]
  },
  SPACES: {
    PATH: '/spaces',
    TITLE: 'Spaces',
    PERMISSIONS: []
  },
  BUDGETS: {
    PATH: '/budgets',
    TITLE: 'Budgets',
    PERMISSIONS: [
      Roles.FINANCIAL_ADMINS,
      Roles.BU_MANAGERS,
      Roles.DEPARTMENT_MANAGERS,
      Roles.PORTFOLIO_MANAGERS,
      Roles.PRODUCT_MANAGERS,
      Roles.VENDOR_MANAGERS
    ]
  },
  VENDOR_MANAGEMENT: {
    PATH: '/vendor-management',
    TITLE: 'Vendor Management',
    PERMISSIONS: [Roles.FINANCIAL_ADMINS, Roles.VENDOR_MANAGERS]
  },
  PRICING: {
    PATH: '/vendor-management/pricing',
    TITLE: 'Pricing',
    PERMISSIONS: [Roles.FINANCIAL_ADMINS, Roles.VENDOR_MANAGERS]
  },
  PROGRAM: {
    PATH: '/vendor-management/program',
    TITLE: 'Program',
    PERMISSIONS: [Roles.FINANCIAL_ADMINS, Roles.VENDOR_MANAGERS]
  },
  SPEND: {
    PATH: '/vendor-management/spend',
    TITLE: 'Spend',
    PERMISSIONS: [Roles.FINANCIAL_ADMINS, Roles.VENDOR_MANAGERS]
  },
  MARKETPLACE: {
    PATH: '/vendor-management/marketplace',
    TITLE: 'Marketplace',
    PERMISSIONS: [Roles.FINANCIAL_ADMINS, Roles.VENDOR_MANAGERS]
  },
  REGISTER: {
    PATH: '/register',
    TITLE: 'Register account'
  },
  LOGIN: {
    PATH: '/login',
    TITLE: 'Login'
  },
  LOGOUT: {
    PATH: '/logout',
    TITLE: 'Logout'
  },
  FORGOT_PASSWORD: {
    PATH: '/forgot-password',
    TITLE: 'Forgot password'
  },
  RESET_PASSWORD: {
    PATH: '/reset-password',
    TITLE: 'Reset password'
  }
}

export default Routes
