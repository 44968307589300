function CreditRepo({ GRAPHQL_URL, GRAPHQL_API_KEY, httpClient }) {
  this.getCreditMapping = ({ token, year }, { success, failure }) => {
    const query = `query MyQuery($token: String!, $year: Int!) {
        getCreditMapping(token: $token, year: $year) {
          error {
            code
            message
            statusCode
          }
          data {
              promotionCode
              accountId
              year
              reference
              amount
              startDate
              endDate
              usedCredit
              elementMap {
                element1Id
                element2Id
                element3Id
                element4Id
              }
            }
        }
      }`

    httpClient.request(
      {
        method: 'POST',
        url: GRAPHQL_URL,
        headers: {
          'x-api-key': GRAPHQL_API_KEY
        },
        data: {
          query,
          variables: {
            token,
            year
          }
        }
      },
      {
        success: (response) => {
          success(response)
        },
        failure: (error) => failure(error)
      }
    )
  }

  this.getCreditMappingByElement = (
    { token, year, element1Id, element2Id, element3Id, element4Id },
    { success, failure }
  ) => {
    const query = `query MyQuery($token: String!, $year: Int!, $element1Id: String!, $element2Id: String!, $element3Id: String!, $element4Id: String!) {
        getCreditMappingByElement(token: $token, year: $year, element1Id: $element1Id, element2Id: $element2Id, element3Id: $element3Id, element4Id: $element4Id) {
          error {
            code
            message
            statusCode
          }
          data {
              promotionCode
              year
              reference
              amount
              startDate
              endDate
              usedCredit
              elementMap {
                element1Id
                element2Id
                element3Id
                element4Id
              }
            }
        }
      }`

    httpClient.request(
      {
        method: 'POST',
        url: GRAPHQL_URL,
        headers: {
          'x-api-key': GRAPHQL_API_KEY
        },
        data: {
          query,
          variables: {
            token,
            year,
            element1Id,
            element2Id,
            element3Id,
            element4Id
          }
        }
      },
      {
        success: (response) => {
          success(response)
        },
        failure: (error) => failure(error)
      }
    )
  }

  this.createCreditMapping = ({ token, creditMapping }, { success, failure }) => {
    const query = `mutation MyMutation ($token: String!, $creditMapping: CreditMappingInput!) {
        createCreditMapping(token: $token, creditMapping: $creditMapping) {
          error {
            statusCode
            message
            code
          }
          data {
              promotionCode
              year
              reference
              amount
              startDate
              endDate
              usedCredit
              elementMap {
                element1Id
                element2Id
                element3Id
                element4Id
              }
            }
        }
      }`

    httpClient.request(
      {
        method: 'POST',
        url: GRAPHQL_URL,
        headers: {
          'x-api-key': GRAPHQL_API_KEY
        },
        data: {
          query,
          variables: {
            token,
            creditMapping
          }
        }
      },
      {
        success: (response) => {
          success(response)
        },
        failure: (error) => failure(error)
      }
    )
  }
}

export default CreditRepo
