import { createColumnHelper } from '@tanstack/react-table'
import dollarPriceFormatter from 'formatters/dollar_price_formatter'
import { getFormattedRow } from 'utils/reports-helper'

const columnHelper = createColumnHelper()

const getUsageTreeColumns = (year, columns, elementNames) => {
  return [
    columnHelper.group({
      header: 'FINANCIAL SCOPE',
      columns: [
        ...(columns.includes('element1')
          ? [
              columnHelper.accessor('element1', {
                header: () => elementNames.element1
              })
            ]
          : []),
        ...(columns.includes('element2')
          ? [
              columnHelper.accessor('element2', {
                header: () => elementNames.element2
              })
            ]
          : []),
        ...(columns.includes('element3')
          ? [
              columnHelper.accessor('element3', {
                header: () => elementNames.element3
              })
            ]
          : []),

        columnHelper.accessor('element4', {
          header: () => elementNames.element4
        }),
        columnHelper.accessor('accountId', {
          header: () => 'Account'
        }),
        columnHelper.accessor('percentage', {
          header: () => 'Allocation'
        })
      ]
    }),
    columnHelper.group({
      header: 'PERIOD',
      columns: [
        columnHelper.accessor((row) => row.values._0.cost, {
          id: 'Jan',
          cell: (info) => getFormattedRow(info.getValue(), year, 0),
          aggregatedCell: ({ getValue }) => dollarPriceFormatter(Math.round(getValue() * 100) / 100),
          aggregationFn: 'sum'
        }),
        columnHelper.accessor((row) => row.values._1.cost, {
          id: 'Feb',
          cell: (info) => getFormattedRow(info.getValue(), year, 1),
          aggregatedCell: ({ getValue }) => dollarPriceFormatter(Math.round(getValue() * 100) / 100),
          aggregationFn: 'sum'
        }),
        columnHelper.accessor((row) => row.values._2.cost, {
          id: 'Mar',
          cell: (info) => getFormattedRow(info.getValue(), year, 2),
          aggregatedCell: ({ getValue }) => dollarPriceFormatter(Math.round(getValue() * 100) / 100),
          aggregationFn: 'sum'
        }),
        columnHelper.accessor((row) => row.values._3.cost, {
          id: 'Apr',
          cell: (info) => getFormattedRow(info.getValue(), year, 3),
          aggregatedCell: ({ getValue }) => dollarPriceFormatter(Math.round(getValue() * 100) / 100),
          aggregationFn: 'sum'
        }),
        columnHelper.accessor((row) => row.values._4.cost, {
          id: 'May',
          cell: (info) => getFormattedRow(info.getValue(), year, 4),
          aggregatedCell: ({ getValue }) => dollarPriceFormatter(Math.round(getValue() * 100) / 100),
          aggregationFn: 'sum'
        }),
        columnHelper.accessor((row) => row.values._5.cost, {
          id: 'Jun',
          cell: (info) => getFormattedRow(info.getValue(), year, 5),
          aggregatedCell: ({ getValue }) => dollarPriceFormatter(Math.round(getValue() * 100) / 100),
          aggregationFn: 'sum'
        }),
        columnHelper.accessor((row) => row.values._6.cost, {
          id: 'Jul',
          cell: (info) => getFormattedRow(info.getValue(), year, 6),
          aggregatedCell: ({ getValue }) => dollarPriceFormatter(Math.round(getValue() * 100) / 100),
          aggregationFn: 'sum'
        }),
        columnHelper.accessor((row) => row.values._7.cost, {
          id: 'Aug',
          cell: (info) => getFormattedRow(info.getValue(), year, 7),
          aggregatedCell: ({ getValue }) => dollarPriceFormatter(Math.round(getValue() * 100) / 100),
          aggregationFn: 'sum'
        }),
        columnHelper.accessor((row) => row.values._8.cost, {
          id: 'Sep',
          cell: (info) => getFormattedRow(info.getValue(), year, 8),
          aggregatedCell: ({ getValue }) => dollarPriceFormatter(Math.round(getValue() * 100) / 100),
          aggregationFn: 'sum'
        }),
        columnHelper.accessor((row) => row.values._9.cost, {
          id: 'Oct',
          cell: (info) => getFormattedRow(info.getValue(), year, 9),
          aggregatedCell: ({ getValue }) => dollarPriceFormatter(Math.round(getValue() * 100) / 100),
          aggregationFn: 'sum'
        }),
        columnHelper.accessor((row) => row.values._10.cost, {
          id: 'Nov',
          cell: (info) => getFormattedRow(info.getValue(), year, 10),
          aggregatedCell: ({ getValue }) => dollarPriceFormatter(Math.round(getValue() * 100) / 100),
          aggregationFn: 'sum'
        }),
        columnHelper.accessor((row) => row.values._11.cost, {
          id: 'Dec',
          cell: (info) => getFormattedRow(info.getValue(), year, 11),
          aggregatedCell: ({ getValue }) => dollarPriceFormatter(Math.round(getValue() * 100) / 100),
          aggregationFn: 'sum'
        })
      ]
    })
  ]
}

export default getUsageTreeColumns
