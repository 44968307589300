const listResources = ({ searchParams, token }, { observer, spacesRepo }) => {
  spacesRepo.listResources(
    { searchParams, token },
    {
      success: (response) => {
        if (response.error || response.data[0] === null) {
          observer.failure()
          return
        }

        observer.success(response.data)
      },
      failure: (error) => {
        observer.failure(error)
      }
    }
  )
}

export default listResources
