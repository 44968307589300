import { createColumnHelper } from '@tanstack/react-table'
import dollarPriceFormatter from 'formatters/dollar_price_formatter'
import { OverlayTrigger, Tooltip } from 'react-bootstrap'

const columnHelper = createColumnHelper()

const getWeeklyAllocationReportColumns = (currentTimeRanges, previousTimeRanges, accountsName, elementNames) => {
  const currentColumns = currentTimeRanges
    .sort((a, b) => b.week - a.week)
    .map((item) => {
      return columnHelper.accessor(
        (row) => {
          const selectedRow = row.breakdowns.find((it) => it.dateRange === item.dateRange)
          if (selectedRow) {
            return row.percentage === 'Claimed' ? selectedRow.claimed : selectedRow.shared
          }
        },
        {
          id: item.dateRange,
          cell: (info) => dollarPriceFormatter(info.getValue()),
          aggregatedCell: ({ getValue }) => dollarPriceFormatter(Math.round(getValue() * 100) / 100),
          aggregationFn: 'sum'
        }
      )
    })

  const previousColumns = previousTimeRanges
    .sort((a, b) => b.week - a.week)
    .map((item) => {
      return columnHelper.accessor(
        (row) => {
          const selectedRow = row.breakdowns.find((it) => it.dateRange === item.dateRange)

          if (selectedRow) {
            return row.percentage === 'Claimed' ? selectedRow.claimed : selectedRow.shared
          }
        },
        {
          id: item.dateRange,
          cell: (info) => dollarPriceFormatter(info.getValue()),
          aggregatedCell: ({ getValue }) => dollarPriceFormatter(Math.round(getValue() * 100) / 100),
          aggregationFn: 'sum'
        }
      )
    })

  const getAccountName = (accountId) => accountsName[accountId]?.accountName

  return [
    columnHelper.group({
      header: 'FINANCIAL SCOPE',
      columns: [
        columnHelper.group({
          header: 'Month',
          columns: [
            columnHelper.accessor((row) => row.elementMap.element4Id, {
              id: 'element4',
              header: () => elementNames.element4
            }),
            columnHelper.accessor('accountId', {
              header: () => 'Account',
              cell: (info) => (
                <>
                  <OverlayTrigger
                    placement="right"
                    overlay={<Tooltip id={`tooltip-right`}>{getAccountName(info.getValue())}</Tooltip>}
                  >
                    <button>{info.getValue()}</button>
                  </OverlayTrigger>
                </>
              )
            }),
            columnHelper.accessor('percentage', {
              header: () => 'Allocation',
              aggregatedCell: () => ''
            })
          ]
        })
      ]
    }),
    columnHelper.group({
      header: 'PERIOD',
      columns: [
        columnHelper.group({
          id: 'Current',
          header: () => 'Current',
          columns: currentColumns
        }),

        columnHelper.group({
          header: 'Previous',
          columns: previousColumns
        })
      ]
    })
  ]
}

export default getWeeklyAllocationReportColumns
