import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'
import './RadioToggleSwitch.scss'

const RadioToggleSwitch = ({ toggleSwitchOptions, name, onChange, selectedOption }) => {
  const [isFixed, setIsFixed] = useState(false)

  useEffect(() => {
    const handleScroll = () => {
      const scrollPosition = window.scrollY
      const scrollThreshold = 70
      if (scrollPosition > scrollThreshold) {
        setIsFixed(true)
      } else if (scrollPosition < 60) {
        setIsFixed(false)
      }
    }

    window.addEventListener('scroll', handleScroll)

    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [])

  return (
    <div className={`radio-toggle-switch-container`}>
      <div role="radiogroup" className={`radio-toggle-switch ${isFixed ? 'radio-toggle-switch-fixed' : ''}`}>
        {toggleSwitchOptions.map((option, index) => {
          return (
            <span key={index}>
              <input
                className="display-none"
                key={index}
                type="radio"
                id={option.value}
                name={name}
                value={option.value}
                onChange={(e) => {
                  onChange(e.target.value)
                }}
                checked={selectedOption === option.value}
              />
              {/* eslint-disable-next-line */}
              <label htmlFor={option.value} className="text-uppercase fw-semibold">
                {option.label}
              </label>
            </span>
          )
        })}
      </div>
    </div>
  )
}

export default RadioToggleSwitch

RadioToggleSwitch.propTypes = {
  toggleSwitchOptions: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired,
  selectedOption: PropTypes.string
}
