function TimePeriodsRepo({ GRAPHQL_URL, GRAPHQL_API_KEY, httpClient }) {
  this.getPeriodCardsForUser = ({ token, year }, { success, failure }) => {
    const query = `mutation ($token: String!, $year: Int!, $month: Int) {
                      getPeriodCardsForUser(actualsSearch: {token: $token, year: $year, month: $month}) {
                        data {
                            usages  {
                              status
                              elementMap {
                                element1Id
                                element2Id
                                element3Id
                                element4Id
                              }
                              accountId
                              percentage
                              breakdowns {
                                week
                                year
                                month
                                shared
                                claimed
                                dateRange
                                isCurrent
                              }
                            }
                            currentTimeRanges {
                               dateRange
                               week
                            }
                            previousTimeRanges {
                               dateRange
                               week
                            }
                        }
                        error {
                          code
                          message
                          statusCode
                        }
                      }
                    }`

    httpClient.request(
      {
        method: 'POST',
        url: GRAPHQL_URL,
        headers: {
          'x-api-key': GRAPHQL_API_KEY
        },
        data: {
          query,
          variables: {
            token,
            year
          }
        }
      },
      {
        success: (response) => {
          const { getPeriodCardsForUser } = response.data.data
          success(getPeriodCardsForUser)
        },
        failure: (error) => failure(error)
      }
    )
  }
}

export default TimePeriodsRepo
