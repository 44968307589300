const getThemePalette = (theme) => {
  const darkTheme = {
    DataColorPalette: {
      Colors: ['#117A8B', '#22D475', '#8A50B3', '#7FD4B9', '#F65D6A', '#4263A6', '#3D993D', '#E673C9'],
      MinMaxGradient: ['#CCF0FF', '#29A8DD'],
      EmptyFillColor: '#F6F7F8'
    },
    UIColorPalette: {
      PrimaryForeground: '#F9FAFC',
      PrimaryBackground: '#2A3439',
      SecondaryForeground: '#F9FAFC',
      SecondaryBackground: '#2A3439',
      Accent: '#FFFFFF',
      AccentForeground: '#A1CD64'
    },
    Typography: { FontFamilies: [{ FontFamily: 'Open Sans' }] }
  }
  const lightTheme = {
    DataColorPalette: {
      Colors: ['#00558C', '#F9B218', '#8A50B3', '#7FD4B9', '#F65D6A', '#4263A6', '#3D993D', '#E673C9'],
      MinMaxGradient: ['#CCF0FF', '#29A8DD'],
      EmptyFillColor: '#F6F7F8'
    },
    UIColorPalette: {
      PrimaryForeground: '#030303',
      PrimaryBackground: '#F9FAFC',
      SecondaryForeground: '#F9FAFC',
      SecondaryBackground: '#A1CD64',
      Accent: '#A1CD64',
      AccentForeground: '#A1CD64'
    },
    Typography: { FontFamilies: [{ FontFamily: 'Open Sans' }] }
  }

  if (theme === 'dark') {
    return darkTheme
  } else {
    return lightTheme
  }
}

export default getThemePalette
