import React, { useContext, useEffect, useState } from 'react'
import AppContext from '../../../context/AppContext'
import LoadingSpinner from '../../../components/common/LoadingSpinner'
import getUserElements from '../use-cases/get-user-elements'
import { Alert, Modal } from 'react-bootstrap'

import './UserMapper.scss'
import Roles from '../../../config/Roles'
import RoleLevels from '../../../config/RoleLevels'
import UsersWithAccess from './UsersWithAccess'
import UsersWithNoAccess from './UsersWithNoAccess'
import UserAllocation from './UserAllocation'

const UserMapper = (props) => {
  const { repoFactory, sorElements, selectedYear, sorElementMapping } = useContext(AppContext)
  const [allocationYear, setAllocationYear] = useState(selectedYear)
  const [isLoading, setIsLoading] = useState(true)
  const [usersWithNoBudget, setUsersWithNoBudget] = useState(null)
  const [usersWithBudget, setUsersWithBudget] = useState(null)
  const [error, setError] = useState(false)
  const [selectedUserForMapping, setSelectedUserForMapping] = useState(null)
  const [isSelectedUserNew, setIsSelectedUserNew] = useState(true)

  useEffect(() => {
    setAllocationYear(selectedYear)

    setUsersWithNoBudget(null)
    setUsersWithBudget(null)
  }, [selectedYear, setAllocationYear, setUsersWithNoBudget, setUsersWithBudget])

  useEffect(() => {
    getAllUserElements()
  }, [allocationYear, repoFactory])

  const getAllUserElements = () => {
    setIsLoading(true)
    getUserElements(
      {
        allocationYear,
        roles: Roles,
        roleLevels: RoleLevels
      },
      {
        userMapperRepo: repoFactory.userMapperRepo(),
        observer: {
          receiveUsersDetails: ({ usersWithNoBudget, usersAndTheirBudget }) => {
            setIsLoading(false)
            setUsersWithNoBudget(usersWithNoBudget)
            setUsersWithBudget(usersAndTheirBudget)
          },
          errorGettingData: () => {
            setIsLoading(false)
            setUsersWithNoBudget(null)
            setUsersWithBudget(null)
            setError(true)
          }
        }
      }
    )
  }

  const mapSelectedUser = ({ selectedUser, isNew }) => {
    setSelectedUserForMapping(selectedUser)
    setIsSelectedUserNew(isNew)
  }

  const updateMappingForUser = (selectedUser) => {
    mapSelectedUser({
      selectedUser,
      isNew: false
    })
  }

  return (
    <>
      {!!sorElements && (
        <div id="user-access-to-budgets">
          {isLoading && <LoadingSpinner />}
          {error && <Alert variant="danger">Error getting accounts</Alert>}

          {usersWithNoBudget && (
            <UsersWithNoAccess
              usersWithNoBudget={usersWithNoBudget}
              isNewUser={isSelectedUserNew}
              selectedUserForMapping={selectedUserForMapping}
              repoFactory={repoFactory}
              sorElements={sorElements}
              allocationYear={allocationYear}
              getUserElements={getAllUserElements}
            />
          )}

          {usersWithBudget && (
            <>
              <hr />
              <UsersWithAccess
                usersWithBudget={usersWithBudget}
                sorElements={sorElements}
                sorElementMapping={sorElementMapping}
                allocationYear={allocationYear}
                setUserForMapping={(item) => {
                  updateMappingForUser(item.rawRowData)
                }}
                {...props}
              />
            </>
          )}

          <Modal
            show={selectedUserForMapping}
            onHide={() => {
              setIsSelectedUserNew(false)
              setSelectedUserForMapping(false)
            }}
            size="xl"
          >
            <Modal.Body>
              <h2>Re-Apply Credit</h2>
              <UserAllocation
                repoFactory={repoFactory}
                selectedUser={selectedUserForMapping}
                isNew={isSelectedUserNew}
                sorElements={sorElements}
                allocationYear={allocationYear}
                getUserElements={getUserElements}
                setSelectedUserForMapping={setSelectedUserForMapping}
                setIsSelectedUserNew={setIsSelectedUserNew}
              />
            </Modal.Body>
          </Modal>
        </div>
      )}
    </>
  )
}

export default UserMapper
