import React, { useEffect, useRef, useState } from 'react'
import EnvironmentForm from './EnvironmentForm'
import DashboardForm from './DashboardForm'
import { Card } from 'react-bootstrap'
import saveDashboardList from 'Admin/Configuration/use-cases/save-dashboard-list-use-cases'
import getDashboardList from 'Admin/Configuration/use-cases/get-dashboard-list-use-case'
import { toast } from 'react-toastify'

const QuickSightEnvironment = ({ repoFactory, setIsLoading }) => {
  const envFormRef = useRef()
  const dashboardFormRef = useRef()
  const [existingDashboardList, setExistingDashboardList] = useState([])
  const [region, setRegion] = useState('')
  const [accountNumber, setAccountNumber] = useState('')
  const [isEnableAmazonQTopics, setIsEnableAmazonQTopics] = useState(false)

  const [error, setError] = useState(false)

  const getDashboards = () => {
    setIsLoading(true)
    getDashboardList(
      {
        token: localStorage.getItem('authToken')
      },
      {
        biRepo: repoFactory.biRepo(),
        observer: {
          receiveDashboardList: (response) => {
            const dashboardList = response.dashboardList || []
            setExistingDashboardList(dashboardList)
            setRegion(response.accountRegion)
            setAccountNumber(response.accountId)
            setIsLoading(false)
            setError(false)
          },
          errorWhileGettingDashboardList: () => {
            setExistingDashboardList([])
            setRegion('')
            setAccountNumber('')
            setIsLoading(false)
            setError(true)
          }
        }
      }
    )
  }

  useEffect(() => {
    getDashboards()
  }, [repoFactory])

  const save = async () => {
    setError(false)
    const dashboardForm = dashboardFormRef.current
    const envForm = envFormRef.current

    if (dashboardForm && envForm) {
      await dashboardForm.submitForm()
      await envForm.submitForm()
      if (dashboardForm.isValid && envForm.isValid) {
        setIsLoading(true)

        const dashboardList = dashboardForm.values.dashboardList.map((dashboard) => {
          let newObj = { ...dashboard }
          delete newObj.isSaved
          return newObj
        })

        const updateQSList = {
          dashboardList,
          accountId: envForm.values.account,
          accountRegion: envForm.values.region,
          token: localStorage.getItem('authToken')
        }

        saveDashboardList(updateQSList, {
          biRepo: repoFactory.biRepo(),
          observer: {
            success: (response) => {
              getDashboards()
              setIsLoading(false)
              toast.success('QuickSight environment saved successfully.')
            },
            failure: () => {
              setError(true)
              setIsLoading(false)
            }
          }
        })
      }
    }
  }

  return (
    <>
      <div className="config-section">
        {error ? <div className="alert alert-danger">There was an error, please try again.</div> : null}
        <Card className="mb-3">
          <Card.Body>
            <h1>QuickSight Environment</h1>
            <hr />
            <EnvironmentForm
              formEl={envFormRef}
              region={region}
              accountNumber={accountNumber}
              setIsEnableAmazonQTopics={setIsEnableAmazonQTopics}
            />
          </Card.Body>
        </Card>
        <Card>
          <Card.Body>
            <h1>QuickSight Assets</h1>
            <hr />
            <DashboardForm
              formEl={dashboardFormRef}
              data={existingDashboardList}
              isEnableAmazonQTopics={isEnableAmazonQTopics}
            />
            <div className="text-center">
              <button className="btn btn-primary" onClick={save}>
                Save
              </button>
            </div>
          </Card.Body>
        </Card>
      </div>
    </>
  )
}
export default QuickSightEnvironment
