import React, { useContext, useEffect, useState } from 'react'
import getActualsForUserNew from 'Actuals/use-cases/get-actuals-for-user-new'
import AppContext from '../../../context/AppContext'
import LoadingSpinner from '../../../components/common/LoadingSpinner'

import UsageTree from '../../../Actuals/components/usage-tree/UsageTree'
import getAccountsMap from 'Summary/use-cases/get-accounts-map'

const MonthlyAllocation = () => {
  const { repoFactory, selectedYear } = useContext(AppContext)
  const [isLoading, setIsLoading] = useState(false)
  const [actualsData, setActualsData] = useState(null)
  const [accounts, setAccounts] = useState(null)

  useEffect(() => {
    getAccountsMap({
      accountMapperRepo: repoFactory.accountMapperRepoNew(),
      observer: {
        errorReceivingAccounts: () => {},
        receiveAccounts: (response) => {
          setAccounts(response)
        }
      }
    })
  }, [repoFactory, setAccounts])

  useEffect(() => {
    setIsLoading(true)
    getActualsForUserNew(
      {
        year: selectedYear,
        token: localStorage.getItem('authToken')
      },
      {
        actualsRepo: repoFactory.actualsRepo(),
        observer: {
          receiveActuals: ({ actuals }) => {
            const convertedData = convertToNewGrouping(actuals)
            setActualsData(convertedData)
            setIsLoading(false)
          },
          errorReceivingActuals: () => {
            setIsLoading(false)
            setActualsData(null)
          }
        }
      }
    )
  }, [selectedYear, repoFactory, setActualsData, setIsLoading])

  return (
    <div className="actuals-data">
      {isLoading && <LoadingSpinner />}
      {actualsData && <UsageTree actualsData={actualsData} year={selectedYear} accountsData={accounts} />}
    </div>
  )
}

export default MonthlyAllocation

function convertToNewGrouping(inputArray) {
  // Group data by element1, element2, element3, element4, accountId
  const groupedByElements = inputArray.reduce((acc, item) => {
    const key = `${item.element1}|${item.element2}|${item.element3}|${item.element4}|${item.accountId}`
    if (!acc[key]) {
      acc[key] = []
    }
    acc[key].push(item)
    return acc
  }, {})

  // Process each group
  const result = Object.entries(groupedByElements).flatMap(([elementsKey, items]) => {
    // Calculate combined claimed values
    const claimedSum = items.reduce((acc, item) => {
      for (let i = 0; i <= 11; i++) {
        const key = `_${i}`
        if (!acc[key]) {
          acc[key] = { ...item.claimedValues[key] }
        } else {
          for (let prop in item.claimedValues[key]) {
            acc[key][prop] = (acc[key][prop] || 0) + item.claimedValues[key][prop]
          }
        }
      }
      return acc
    }, {})

    const [element1, element2, element3, element4, accountId] = elementsKey.split('|')

    // Determine if we should show the claimed row
    const showClaimed = !(items.length === 1 && items[0].percentage === 100)

    // Create a new group for combined claimed values if needed
    let claimedGroup
    if (showClaimed) {
      claimedGroup = {
        element1,
        element2,
        element3,
        element4,
        accountId,
        percentage: 'Claimed',
        values: claimedSum
      }
    }

    // Modify original items
    const modifiedItems = items.map((item) => {
      const { values, claimedValues, sharedValues, ...rest } = item
      return {
        ...rest,
        values: sharedValues
      }
    })

    // Return modified original items plus the new claimed group if it exists
    return claimedGroup ? [claimedGroup, ...modifiedItems] : modifiedItems
  })

  return result
}
