const inviteUserUseCase = ({ username, email, role }, { observer, userRepo }) => {
  userRepo.inviteUser(
    { username, email, role },
    {
      success: (data) => {
        if (!data.createUser) {
          observer.failure()
          return
        }
        observer.success()
      },
      failure: (error) => {
        observer.failure(error)
      }
    }
  )
}

export default inviteUserUseCase
